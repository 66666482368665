import {EcomComponent} from '@wix/ecom-platform-sdk';
import {ecomAppDefID} from '../constants';

interface AppEcomComponentsData {
  [key: string]: {
    ecomComponents: {
      [key in EcomComponent]: {
        isInstalled: boolean;
      };
    };
    managingAppId?: string;
  };
}

export class EcomPlatformInstallationState {
  private ecomComponents: {[key: string]: EcomComponent[]} = {};
  private ecomComponentsData: AppEcomComponentsData = {};

  public addInstallAppComponents(appDefId: string, installedComponents: EcomComponent[]) {
    this.ecomComponents[appDefId] = installedComponents;

    const configEcomCompToObj = installedComponents.reduce((result, item: EcomComponent) => {
      result[item] = {isInstalled: true};
      return result;
    }, {});
    this.ecomComponentsData[appDefId] = {
      ecomComponents: configEcomCompToObj as {
        [key in EcomComponent]: {
          isInstalled: boolean;
        };
      },
      managingAppId: appDefId,
    };
  }

  public addInstallAppComponentsData(
    appDefId: string,
    ecomComponents: {ecomComponents: {[key in EcomComponent]: {isInstalled: boolean}}; managingAppId?: string}
  ) {
    this.ecomComponents[appDefId] = Object.keys(ecomComponents.ecomComponents).map((comp: EcomComponent) => comp);
    this.ecomComponentsData[appDefId] = ecomComponents;
  }

  public getTheAppEcomComponents(appDefId: string): EcomComponent[] {
    return Object.keys(this.ecomComponentsData[appDefId]?.ecomComponents || []).map((comp: EcomComponent) => comp);
  }

  public getTheAppManageAppId(): string {
    if (this.getInstalledAppDefIds().length === 1) {
      const verticalAppId = this.getInstalledAppDefIds()[0];
      return this.ecomComponentsData[verticalAppId].managingAppId ?? verticalAppId;
    } else {
      return ecomAppDefID;
    }
  }

  public removeApp(appDefId: string) {
    if (this.ecomComponents.hasOwnProperty(appDefId)) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.ecomComponents[appDefId];
    }
    if (this.ecomComponentsData.hasOwnProperty(appDefId)) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.ecomComponentsData[appDefId];
    }
  }

  public hasInstalledApps() {
    return !!this.getInstalledAppDefIds().length;
  }

  public isMoreThanOneAppInstalled() {
    return this.getInstalledAppDefIds().length > 1;
  }

  public getInstalledAppDefIds() {
    return Object.keys(this.ecomComponentsData);
  }
}
